.main-page {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.square-component {
  width: 100vh; /* 视口高度，确保正方形 */
  height: 100vh;
  position: relative;
}

.protrait-component {
  position: relative;
}

.header {
  height: calc(100vh / 6); /* 页面高度的1/6 */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  mix-blend-mode: multiply; /* 正片叠底混合模式 */
}

.logo {
  width: auto;
  height: 100%;
  margin: 0;
}

.logo.mobile {
  height: auto;
  max-width: 100%;
}

.about-button {
  background-color: white; /* 白色背景 */
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  width: 15vh;
  height: 4vh;
  font-size: 2vh;
  margin-bottom: 2vh;
}

.about-button.mobile {
  position: absolute;
  right: 0;
  margin-bottom: 2vw;
}

.title-container {
  height: calc(100vh / 6);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 2.5vh;
  line-height: 3.5vh;
  mix-blend-mode: multiply; /* 正片叠底混合模式 */
}

.footer-container {
  height: calc(100vh / 6);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}

.footer-container.mobile {
  width: 100%;
  /* position: absolute; */
  bottom: 0;
  /* z-index: 1; */
}

.desc-container {
  display: inline;
  font-size: 2.5vh;
  line-height: 3.5vh;
  mix-blend-mode: multiply; /* 正片叠底混合模式 */
}

.divider {
  width: 100%;
  height: 0.5vh;
  margin: 1vh 0;
  mix-blend-mode: multiply; /* 正片叠底混合模式 */
}

