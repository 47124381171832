.about-page-content {
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.about-square-container {
  width: 100vh; /* 视口高度，确保正方形 */
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.square-component.mobile {
  justify-content: center;
} 

.about-image1 {
  width: auto;
  height: 100%;
}

.back-button1 {
  position: absolute;
  top: 4vh;
  left: 3vh;
  width: 5vh;
  height: 5vh;
  cursor: pointer;
}

.about-image-container {
  width: 48%;
  height: 100%;
  overflow-y: auto; /* 允许容器内的图片垂直滚动 */
}

.about-image-container.mobile {
  width: 100%;
  align-self: center;
}