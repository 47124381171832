.guest-square-container {
  display: flex;
  flex-direction: column;
}

.guest-name {
  font-size: 2.5vh;
  height: 3.5vh;
  mix-blend-mode: multiply;
}

.guest-square {
  position: relative;
  width: 100%; /* 保证宽度与父容器相同 */
  padding-top: 100%; /* 顶部填充保持宽高比为1:1，实现正方形 */
  cursor: pointer;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease;
}

.guest-square:hover .background-image {
  transform: translate(-0.8vh, -0.8vh); 
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 50%的黑色遮罩 */
  transition: opacity 0.3s ease;
}

.guest-square:hover .mask {
  opacity: 0; /* 鼠标悬停时遮罩透明度变为0 */
}

.icon-image {
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  height: 50%;
  border-radius: 50%; /* 圆形图片 */
  transition: opacity 0.3s ease;
}

.guest-square:hover .icon-image {
  opacity: 0.5; /* 鼠标悬停时图片透明度变为50% */
}

.guest-list-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  min-height: 50vh;
}

.guest-list-container.mobile {
  padding: 3vw 3vw;
}

.guest-list {
  display: flex;
  flex-wrap: wrap;
  gap: 2vh; /* 为了简化计算，使用gap代替计算间隔 */
}
.guest-list.mobile {
  gap: 4vw;
}


.anim-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 22.5vh;
  height: 5vh;
  font-size: 2vh;
}

.button-main {
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
}

.button-hover {
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  transition: width 0.5s; /* 0.5秒内宽度变化的过渡效果 */
  cursor: pointer; /* 鼠标悬浮效果 */
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
}

.anim-button:hover .button-hover {
  width: 100%; /* 鼠标悬浮时覆盖整个按钮 */
}
