.moving-background {
  height: 100vh;
  background-image: url("/src/Assets/images/homebg.png");
  background-size: contain;
  background-repeat: repeat; /* 平铺 */
  animation: slide 10s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}

.moving-background.mobile {
  background-size: cover;
  overflow: hidden;
}

@keyframes slide {
  from {
    background-position: 100vh 0;
  }
  to {
    background-position: 0 0;
  }
}


.main-anim-button {
  width: auto; /* 根据图片尺寸自动调整宽度 */
  height: calc(100vh / 3);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-anim-button.mobile {
  max-width: 100vw;
  overflow: hidden;
  justify-content: flex-start;
}

.main-button-image {
  height: 100%;
  width: auto; /* 保持图片的原始宽高比 */
}

.main-button-image.mobile {
  left: 0;
}

.main-hover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0; /* 初始状态下宽度为0 */
  background-image: url("/src/Assets/images/homebtn2.png");
  background-size: cover;
  transition: width 0.5s; /* 0.5秒内宽度变化的过渡效果 */
  cursor: pointer; /* 鼠标悬浮效果 */
}

.main-anim-button:hover .main-hover-overlay {
  width: 100%; /* 鼠标悬浮时覆盖整个按钮 */
}
